import React from 'react';
import { useSiteMetadata } from '../hooks/use-site-metadata';
import PropTypes from 'prop-types';

import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
  TelegramShareButton,
  TelegramIcon,
  EmailShareButton,
  EmailIcon,
  InstapaperShareButton,
  InstapaperIcon,
  WhatsappShareButton,
  WhatsappIcon,
  RedditShareButton,
  RedditIcon,
} from 'react-share';

const ShareButtons = ({
  title,
  description,
  url,
  tags,
  iconSize,
}) => {
  const { siteMetadata } = useSiteMetadata();
  const twitterHandle = siteMetadata.social.twitter;

  return (
    <div className="space-x-1">
      <TwitterShareButton
        url={url}
        title={title}
        via={twitterHandle}
        hashtags={tags.map((t) => t.replace(/[^a-zA-Z0-9]+/g, ''))}
      >
        <TwitterIcon size={iconSize} round={true} />
      </TwitterShareButton>
      <LinkedinShareButton
        url={url}
        title={title}
        summary={description}
        source={siteMetadata.siteUrl}
      >
        <LinkedinIcon size={iconSize} round={true} />
      </LinkedinShareButton>
      <RedditShareButton url={url} title={title}>
        <RedditIcon size={iconSize} round={true} />
      </RedditShareButton>
      <FacebookShareButton url={url} quote={title}>
        <FacebookIcon size={iconSize} round={true} />
      </FacebookShareButton>
      <WhatsappShareButton url={url} title={'"' + title + '"'}>
        <WhatsappIcon size={iconSize} round={true} />
      </WhatsappShareButton>
      <TelegramShareButton url={url} title={title}>
        <TelegramIcon size={iconSize} round={true} />
      </TelegramShareButton>
      <InstapaperShareButton
        url={url}
        title={title}
        description={description}
      >
        <InstapaperIcon size={iconSize} round={true} />
      </InstapaperShareButton>
      <EmailShareButton
        url={url}
        subject={title}
        body={description}
        separator={'\n'}
      >
        <EmailIcon size={iconSize} round={true} />
      </EmailShareButton>
    </div>
  );
};

ShareButtons.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
  iconSize: PropTypes.number,
};
ShareButtons.defaultProps = {
  iconSize: 40,
};

export default ShareButtons;

import * as React from 'react';
import PropTypes from 'prop-types';
import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';

import { useSiteMetadata } from '../hooks/use-site-metadata';

import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
  TelegramShareButton,
  TelegramIcon,
  EmailShareButton,
  EmailIcon,
  InstapaperShareButton,
  InstapaperIcon,
  WhatsappShareButton,
  WhatsappIcon,
  RedditShareButton,
  RedditIcon,
} from 'react-share';

function SocialOverlay({ post, location }) {
  const [open, setOpen] = useState(true);

  const { siteMetadata } = useSiteMetadata();

  const url = siteMetadata.siteUrl + location.pathname;
  const title = post.frontmatter.title;
  const description = post.frontmatter.description;
  const tags = post.frontmatter.tags;
  const twitterHandle = siteMetadata.social.twitter;

  const iconSize = 60;

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 overflow-hidden"
        open={open}
        onClose={setOpen}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-y-0 right-0 flex max-w-full pl-10">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-md">
                <div className="flex flex-col h-full py-6 overflow-y-scroll bg-white shadow-xl">
                  <div className="px-4 sm:px-6">
                    <div className="flex items-start justify-between">
                      <Dialog.Title className="text-lg font-medium text-gray-900">
                        <strong>
                          Share this article on social media
                        </strong>
                      </Dialog.Title>
                      <div className="flex items-center ml-3 h-7">
                        <button
                          className="text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          onClick={() => setOpen(false)}
                        >
                          <span className="sr-only">Close panel</span>
                          <XIcon
                            className="w-6 h-6"
                            aria-hidden="true"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="relative flex-1 px-4 mt-6 sm:px-6">
                    <div className="relative">
                      If you liked the contents of this article,
                      please share it with your friends!
                    </div>
                    <div className="relative grid grid-cols-4 gap-4 mt-4">
                      <div>
                        <TwitterShareButton
                          url={url}
                          title={title}
                          via={twitterHandle}
                          hashtags={tags.map((t) =>
                            t.replace(/[^a-zA-Z0-9]+/g, ''),
                          )}
                        >
                          <TwitterIcon
                            size={iconSize}
                            round={false}
                          />
                        </TwitterShareButton>
                      </div>

                      <div>
                        <LinkedinShareButton
                          url={url}
                          title={title}
                          summary={description}
                          source={siteMetadata.siteUrl}
                        >
                          <LinkedinIcon
                            size={iconSize}
                            round={false}
                          />
                        </LinkedinShareButton>
                      </div>

                      <div>
                        <RedditShareButton url={url} title={title}>
                          <RedditIcon size={iconSize} round={false} />
                        </RedditShareButton>
                      </div>

                      <div>
                        <FacebookShareButton url={url} quote={title}>
                          <FacebookIcon
                            size={iconSize}
                            round={false}
                          />
                        </FacebookShareButton>
                      </div>

                      <div>
                        <WhatsappShareButton
                          url={url}
                          title={'"' + title + '"'}
                        >
                          <WhatsappIcon
                            size={iconSize}
                            round={false}
                          />
                        </WhatsappShareButton>
                      </div>

                      <div>
                        <TelegramShareButton url={url} title={title}>
                          <TelegramIcon
                            size={iconSize}
                            round={false}
                          />
                        </TelegramShareButton>
                      </div>

                      <div>
                        <InstapaperShareButton
                          url={url}
                          title={title}
                          description={description}
                        >
                          <InstapaperIcon
                            size={iconSize}
                            round={false}
                          />
                        </InstapaperShareButton>
                      </div>

                      <div>
                        <EmailShareButton
                          url={url}
                          subject={title}
                          body={description}
                          separator={'\n'}
                        >
                          <EmailIcon size={iconSize} round={false} />
                        </EmailShareButton>
                      </div>
                    </div>
                    <div className="relative mt-6">Thank you!</div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

SocialOverlay.propTypes = {
  post: PropTypes.object.isRequired,
  lcoation: PropTypes.object.isRequired,
};
export default SocialOverlay;

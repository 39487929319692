export const ImageModel = ({ url, width, height }) => {
  if (url == null) {
    return null;
  }

  return {
    '@type': `ImageObject`,
    url,
    width: Math.floor(width),
    height: Math.floor(height),
  };
};

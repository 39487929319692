import { useStaticQuery, graphql } from 'gatsby';
import { getSrc, getImage } from 'gatsby-plugin-image';

export const useSiteLogo = () => {
  const { logo, site } = useStaticQuery(
    graphql`
      query LogoImage {
        logo: file(relativePath: { eq: "logo.png" }) {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
        site {
          siteMetadata {
            author {
              name
              summary
              href
            }
          }
        }
      }
    `,
  );

  const author = site.siteMetadata.author;

  return {
    siteLogo: {
      image: getImage(logo),
      src: getSrc(getImage(logo)),
      imageAlt: `Logo of ${author.name}`,
      imageTitleHtml: `<a href="${author.href}" title="${author.name} ${author.summary}">${author.name}</a>`,
    },
  };
};

import * as React from 'react';
import { useQueryParam, BooleanParam } from 'use-query-params';

import { Link, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import ArticleSchema from './json-ld/article-schema';

import {
  ArrowNarrowLeftIcon,
  ArrowNarrowRightIcon,
} from '@heroicons/react/solid';

import { useSiteMetadata } from '../hooks/use-site-metadata';

import Layout from '../components/homepage-layout';
import BlogPostAuthor from '../components/blog-post-author';
import ShareButtons from '../components/share-buttons';
import SocialOverlay from '../components/social-overlay';

export const pageQuery = graphql`
  query BlogPostBySlug($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      timeToRead
      frontmatter {
        title
        description
        date
        lastUpdated
        formattedDate: date(formatString: "MMMM DD, YYYY")
        formattedLastUpdated: lastUpdated(
          formatString: "MMMM DD, YYYY"
        )
        slug
        canonical
        category {
          title
          href
        }
        tags
        featuredImage {
          image {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, width: 730)
            }
          }
          imageAlt
          imageTitleHtml
        }
        previousArticle {
          title
          href
        }
        nextArticle {
          title
          href
        }
      }
    }
  }
`;

// tableOfContents
// headings {
//   id
//   value
// }

// renders a canonical link for the page
function renderCanonicalLink(frontmatter, pageHref) {
  return frontmatter?.canonical || pageHref;
}

const BlogPost = ({ data, location }) => {
  const { siteMetadata } = useSiteMetadata();

  const post = data.markdownRemark;
  const title = post.frontmatter.title;
  const description = post.frontmatter.description;
  const category = post.frontmatter.category;

  const featuredImage = post.frontmatter.featuredImage;

  const previousArticle = post.frontmatter?.previousArticle;
  const nextArticle = post.frontmatter?.nextArticle;
  const hasNav = previousArticle || nextArticle;

  const absoluteURL = siteMetadata.siteUrl + location.pathname;
  const canonicalURL = renderCanonicalLink(
    post.frontmatter,
    absoluteURL,
  );

  const [social] = useQueryParam('social', BooleanParam);

  return (
    <Layout
      title={title}
      description={description}
      tags={post.frontmatter.tags}
      image={featuredImage}
      canonicalURL={canonicalURL}
      absoluteURL={absoluteURL}
    >
      <ArticleSchema
        title={title}
        description={description}
        date={post.frontmatter.date}
        lastUpdated={post.frontmatter.formattedLastUpdated}
        tags={post.frontmatter.tags}
        image={featuredImage}
        canonicalURL={canonicalURL}
      />

      <div className="relative py-16 overflow-hidden">
        <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
          <div
            className="relative h-full mx-auto text-lg max-w-prose"
            aria-hidden="true"
          >
            {/* Bottom-right dots */}
            <svg
              className="absolute transform translate-x-32 bottom-12 left-full"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="d3eb07ae-5182-43e6-857d-35c643af9034"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
              />
            </svg>
          </div>
        </div>
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="mx-auto text-lg max-w-prose">
            <h1>
              {category && (
                <span className="block text-base font-semibold tracking-wide text-center text-indigo-600 uppercase">
                  <Link
                    to={category.href}
                    title={'Category: ' + category.title}
                  >
                    {category.title}
                  </Link>
                </span>
              )}
              <span className="block mt-2 text-3xl font-extrabold leading-8 tracking-tight text-center text-gray-900 sm:text-4xl">
                {title}
              </span>
            </h1>
            <BlogPostAuthor
              author={siteMetadata.author.name}
              href={siteMetadata.author.href}
              date={post.frontmatter.date}
              formattedDate={post.frontmatter.formattedDate}
              timeToRead={post.timeToRead}
            />
            {social && (
              <SocialOverlay post={post} location={location} />
            )}
            <p className="mt-8 text-xs leading-8 text-center text-gray-500" />
          </div>
          <div className="mx-auto max-w-prose">
            <figure>
              <GatsbyImage
                className="w-full rounded-lg"
                imgStyle={{ marginTop: '0 !important' }}
                image={getImage(featuredImage.image)}
                alt={featuredImage.imageAlt}
              />
              <figcaption
                className="prose text-center prose-xs"
                dangerouslySetInnerHTML={{
                  __html: featuredImage.imageTitleHtml,
                }}
              />
            </figure>
          </div>
          <div
            className="mx-auto mt-6 prose prose-lg text-gray-500 prose-indigo"
            dangerouslySetInnerHTML={{
              __html: post.html,
            }}
            itemProp="articleBody"
          />

          <div className="mt-8 text-xs leading-8 text-center">
            <p className="pt-2 pb-4 text-base text-center text-gray-400">
              If you enjoyed this post, please share it with your
              friends!
            </p>
            <ShareButtons
              url={siteMetadata.siteUrl + location.pathname}
              title={post.frontmatter.title}
              description={post.frontmatter.description}
              tags={post.frontmatter.tags}
            />
          </div>

          {hasNav && (
            <nav
              className="flex items-center justify-between mx-auto mt-20 prose-lg border-t border-gray-200 sm:px-0"
              style={{ maxWidth: '65ch' }}
            >
              <div className="flex flex-1 w-1/2 -mt-px">
                {previousArticle && (
                  <Link
                    to={previousArticle.href}
                    title={previousArticle.title}
                    rel="prev"
                    className="inline-flex items-center pt-4 pr-1 text-sm font-medium text-gray-500 border-t-2 border-transparent hover:text-gray-700 hover:border-gray-300"
                  >
                    <ArrowNarrowLeftIcon
                      className="w-5 h-5 mr-3 text-gray-400"
                      aria-hidden="true"
                    />
                    {previousArticle.title}
                  </Link>
                )}
              </div>
              <div className="flex justify-end flex-1 w-1/2 -mt-px">
                {nextArticle && (
                  <Link
                    to={nextArticle.href}
                    title={nextArticle.title}
                    rel="next"
                    className="inline-flex items-center pt-4 pl-1 text-sm font-medium text-gray-500 border-t-2 border-transparent hover:text-gray-700 hover:border-gray-300"
                  >
                    {nextArticle.title}{' '}
                    <ArrowNarrowRightIcon
                      className="w-5 h-5 ml-3 text-gray-400"
                      aria-hidden="true"
                    />
                  </Link>
                )}
              </div>
            </nav>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default BlogPost;

import * as React from 'react';
import { Helmet } from 'react-helmet';

import PropTypes from 'prop-types';

import { useSiteMetadata } from '../../hooks/use-site-metadata';
import { useSiteLogo } from '../../hooks/use-site-logo';
import { getSrc, getImage } from 'gatsby-plugin-image';

import { AuthorModel } from './model/author';
import { ImageModel } from './model/image';
import { PublisherModel } from './model/publisher';

function ArticleSchema({
  title,
  description,
  date,
  lastUpdated,
  tags,
  image,
  canonicalURL,
}) {
  const { siteMetadata } = useSiteMetadata();
  const { siteLogo } = useSiteLogo();

  const authorProfiles = [
    siteMetadata.siteUrl,
    siteMetadata.social?.Twitter
      ? `https://twitter.com/${siteMetadata.social.Twitter}`
      : null,
    siteMetadata.social?.LinkedIn
      ? `https://linkedin.com/in/${siteMetadata.social.LinkedIn}`
      : null,
    siteMetadata.social?.GitHub
      ? `https://github.com/${siteMetadata.social.GitHub}`
      : null,
  ].filter((key) => key != null);

  const img = image ? getImage(image.image) : null;
  const imgSrc = image ? getSrc(image.image) : null;

  const logoImage = ImageModel({
    url: siteMetadata.siteUrl + siteLogo.src,
    width: siteLogo.image.width,
    height: siteLogo.image.height,
  });

  // https://developers.google.com/search/docs/data-types/article#amp
  const jsonData = {
    '@context': `https://schema.org/`,
    '@type': `Article`,
    author: AuthorModel({
      name: siteMetadata.author.name,
      sameAs: authorProfiles,
      image: logoImage,
    }),
    headline: title,
    url: canonicalURL,
    description: description,
    keywords: tags.join(','),
    datePublished: date,
    dateModified: lastUpdated || date,
    image: ImageModel({
      url: siteMetadata.siteUrl + imgSrc,
      width: img?.width,
      height: img?.height,
      description: image.imageAlt,
    }),
    publisher: PublisherModel({
      name: siteMetadata.title,
      logo: logoImage,
    }),
    mainEntityOfPage: {
      '@type': `WebPage`,
      '@id': siteMetadata.siteUrl,
    },
  };

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(jsonData, undefined, 4)}
      </script>
    </Helmet>
  );
}
ArticleSchema.defaultProps = {
  tags: [],
};

ArticleSchema.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  date: PropTypes.string,
  lastUpdated: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
  image: PropTypes.object,
  canonicalURL: PropTypes.string,
};

export default ArticleSchema;
